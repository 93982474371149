import validate from "/usr/prnews/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@types+node@18.17.5_@unocss+reset@0.61.0_eslint@9.10.0_f_srj2rddnykm7zg4gtre6ifwub4/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/usr/prnews/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@types+node@18.17.5_@unocss+reset@0.61.0_eslint@9.10.0_f_srj2rddnykm7zg4gtre6ifwub4/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/usr/prnews/middleware/auth.ts"),
  "not-auth": () => import("/usr/prnews/middleware/notAuth.ts"),
  publisher: () => import("/usr/prnews/middleware/publisher.ts"),
  "publisher-flow": () => import("/usr/prnews/middleware/publisherFlow.ts"),
  "uncompleted-survey": () => import("/usr/prnews/middleware/uncompletedSurvey.ts")
}