<script setup lang="ts">
import Modal from '~/components/organisms/Modal.vue'
import Button from '~/components/molecules/Button.vue'

const handleError = () => clearError({ redirect: '/dashboard' })
</script>

<template>
  <Modal :open="true" class="!m-auto">
    <template #header>
      Something Went Wrong
    </template>
    <template #body>
      You’ve done nothing wrong. Something has happened on our end. If the error persists, come back later when we hopefully have fixed it.
    </template>
    <Button class="w-full justify-center" @click="handleError">
      Got It
    </Button>
  </Modal>
</template>

<style scoped lang="postcss">
</style>
