<script lang="ts" setup>
const { notifications } = useNotification()
</script>

<template>
  <div v-if="notifications.length" class="absolute z-50 top-12 right-12 flex flex-col gap-4">
    <div
      v-for="notification in notifications"
      :key="notification.content"
      class="text-body-regular-12 px-2 py-1 w-60 rounded-sm shadow-large-dense"
      :class="[`notification--${notification.type}`]"
    >
      {{ notification.content }}
    </div>
  </div>
</template>

<style scoped lang="postcss">
.notification {
  &--info {
    @apply bg-black-100 text-white-100;
  }
  &--success {
    @apply bg-light-success text-black-100;
  }
  &--error {
    @apply bg-light-error text-black-100;
  }
}
</style>
