import revive_payload_client_xa0PzcwRtQ from "/usr/prnews/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@types+node@18.17.5_@unocss+reset@0.61.0_eslint@9.10.0_f_srj2rddnykm7zg4gtre6ifwub4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_eI7CyrR1Uu from "/usr/prnews/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@types+node@18.17.5_@unocss+reset@0.61.0_eslint@9.10.0_f_srj2rddnykm7zg4gtre6ifwub4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GeQqyGzCAi from "/usr/prnews/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@types+node@18.17.5_@unocss+reset@0.61.0_eslint@9.10.0_f_srj2rddnykm7zg4gtre6ifwub4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import posthog_client_YgJWEIoeNO from "/usr/prnews/node_modules/.pnpm/nuxt-posthog@1.5.2_rollup@4.18.0/node_modules/nuxt-posthog/dist/runtime/plugins/posthog.client.js";
import supabase_client_Yi5rS7EWnc from "/usr/prnews/node_modules/.pnpm/@nuxtjs+supabase@1.2.2_rollup@4.18.0/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.mjs";
import payload_client_euNFyy7x6d from "/usr/prnews/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@types+node@18.17.5_@unocss+reset@0.61.0_eslint@9.10.0_f_srj2rddnykm7zg4gtre6ifwub4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_USY4D9rfIs from "/usr/prnews/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@types+node@18.17.5_@unocss+reset@0.61.0_eslint@9.10.0_f_srj2rddnykm7zg4gtre6ifwub4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_9relT2Uthj from "/usr/prnews/node_modules/.pnpm/@pinia+nuxt@0.5.1_rollup@4.18.0_typescript@5.3.3_vue@3.4.30/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/usr/prnews/.nuxt/components.plugin.mjs";
import prefetch_client_gQgN6D9z9p from "/usr/prnews/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@types+node@18.17.5_@unocss+reset@0.61.0_eslint@9.10.0_f_srj2rddnykm7zg4gtre6ifwub4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_WuPPLA6zm4 from "/usr/prnews/node_modules/.pnpm/nuxt-echarts@0.2.3_echarts@5.5.1_rollup@4.18.0/node_modules/nuxt-echarts/dist/runtime/plugin.js";
import sentry_client_WhYFG9qq2I from "/usr/prnews/node_modules/.pnpm/@sentry+nuxt@8.28.0_@opentelemetry+api@1.9.0_@opentelemetry+core@1.25.1_@opentelemetry+instru_z734qfcspocdikf55o7utfubnq/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/usr/prnews/.nuxt/sentry-client-config.mjs";
import directives_LyaMtxMCy5 from "/usr/prnews/node_modules/.pnpm/nuxt-posthog@1.5.2_rollup@4.18.0/node_modules/nuxt-posthog/dist/runtime/plugins/directives.js";
import i18n_HO8FrvAYVk from "/usr/prnews/node_modules/.pnpm/@nuxtjs+i18n@8.3.1_rollup@4.18.0_vue@3.4.30/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_m0T0VntOvS from "/usr/prnews/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@types+node@18.17.5_@unocss+reset@0.61.0_eslint@9.10.0_f_srj2rddnykm7zg4gtre6ifwub4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import validate_fk94mvjWUp from "/usr/prnews/plugins/validate.ts";
import vueQuery_HbXfDZmQp2 from "/usr/prnews/plugins/vueQuery.ts";
export default [
  revive_payload_client_xa0PzcwRtQ,
  unhead_eI7CyrR1Uu,
  router_GeQqyGzCAi,
  posthog_client_YgJWEIoeNO,
  supabase_client_Yi5rS7EWnc,
  payload_client_euNFyy7x6d,
  check_outdated_build_client_USY4D9rfIs,
  plugin_vue3_9relT2Uthj,
  components_plugin_KR1HBZs4kY,
  prefetch_client_gQgN6D9z9p,
  plugin_WuPPLA6zm4,
  sentry_client_WhYFG9qq2I,
  sentry_client_config_o34nk2sJbg,
  directives_LyaMtxMCy5,
  i18n_HO8FrvAYVk,
  chunk_reload_client_m0T0VntOvS,
  validate_fk94mvjWUp,
  vueQuery_HbXfDZmQp2
]