export enum ButtonType {
  Accent = 'accent',
  Secondary = 'secondary',
  Link = 'link',
  Header = 'header'
}

export enum ButtonSize {
  Big = 'big',
  Small = 'small'
}
